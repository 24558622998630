.App {
  text-align: center;
}

.App-header {
  background-color: #124;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: 'Inconsolata', monospace;
}

.App-header h1 {
  font-size: calc(10px + 6vmin);
  margin: .35em 0 .35em 0;
}

.socialmedia-container {
  display: flex;
  margin-top: 1em;
}

.socialmedia-container a {
  color: white;
  margin: 0 .5em 0 .5em;
}